
.card {
    font-family: $themefont;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.12);
    color: $themecardtextcolor;
    background:$themecardbackgroundcolor;
    border-radius: 2px;
    margin: 1rem;
    .title {
        line-height: 1.8rem;
        font-size: 1.4rem;
        font-weight: 600;
        color: $themecardtitletextcolor;
    }
    .content {
        padding: 1.3rem;
        font-weight: 300;
        border-radius: 0 0 2px 2px;
        background:$themecardbackgroundcolor;
        p, ul, li{
            margin-bottom: 0.1rem;
        }
    }


    .action {
        border-top: 1px solid rgba(160, 160, 160, 0.2);
        padding: 1.3rem;
    }
    a {
        color: $themecolorlinktext;
        //margin-right: 1.3rem;
        transition: color 0.3s ease;
        // text-transform: uppercase;
        text-decoration: none;
    }
    .image {
        position: relative;
        .title {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 1.3rem;
            color: $themecardbackgroundcolor;
        }
        img {
            border-radius: 2px 2px 0 0;
        }
    }
    .topcontent{
        padding: 1.3rem;
        font-weight: 300;
        border-radius: 0 0 2px 2px;
    }
}


.navcard {
    @extend .card;
    font-size: 80%;
    padding: 0.0rem;
    p {
        margin: 0;
    }
    .content {
        font-weight: 600;
        margin: 0.0rem;
        padding-left: 1.3rem;
        padding-right: 1.3rem;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        text-color: $themecardtextcolor;
    }
    a {
        color: $themecolorlinktext;
        margin-right: 1.3rem;
        transition: color 0.3s ease;
        text-transform: uppercase;
        text-decoration: none;
        &:hover {
            color: $themecolorlinktextlight;
        }
    }
}

