@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro);
//@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:bold);
//@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:semibold);
//@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900&subset=latin);




// ---------------------


$breakpoint-classes: (small medium large xlarge);

// ---------------------



/*
Idee: keinen magenta-anteil, keinen rot-anteil

http://www.monolithdesign.com/class-html/examples/Color-Index-RGB-CMYK.html

3278          100    0   55    5    #00f26d
328           100    0   45   32    #00ad5f
3282          100    0   46   15    #00d975
3285          100    0   50    7    #00ed77
3288          100    0   54   20    #00cc5e
329           100    0   46   46    #008a4a
3292          100    0   49   46    #008a46
3295          100    0   53   21    #00c95f
3298          100    0   57   42    #009440
330           100    0   48   60    #006635

334           100    0   60    3    #00f763
335           100    0   65   30    #00b33e
336           100    0   67   47    #00872d

340           100    0   66    9    #00e84f
                                             3405           85    0   65    0    #26ff59
341           100    0   67   29    #00b53c
3415          100    0   77   22    #00c72e
342           100    0   71   43    #00912a
3425          100    0   78   42    #009421
343            98    0   72   61    #02631c
3435          100    0   81   66    #005710

347  100    0   86    3         #00f723
348  100    0   85   24         #00c21d
349  100    0   91   42         #00940d

                    die tabelle ist aber scheisse

-----------------------------------------------------------------------
-----------------------------------------------------------------------

                    Besser die Farbwerte von Pantone
*/

// 353C
$themepantonecolor0: #80E0A7;
// 354C
$themepantonecolor1: #00B140;
// 355C
$themepantonecolor2: #009639 ;
// 356C
$themepantonecolor3: #007A33 ;

$themecolor: $themepantonecolor1;
$themecolorlightborder: lighten($themecolor,4%);
$themecolordarkborder: darken($themecolor,1%);
$themecolorlinktext: darken($themecolor,15%);;
$themecolorlinktextlight: lighten($themecolor,15%);

$themecardtextcolor: #333;
$themecardtitletextcolor: #111;
$themecardbackgroundcolor: #fdfdfd;

$themelabelcolor0: hsla(101, 100%, 55%, 0.75);
$themelabelcolor1: hsla(81, 100%, 50%, 0.75);
$themelabelcolor2: hsla(61, 100%, 50%, 0.75);
$themelabelcolor3: hsla(32,100%,50%,0.75);
//$themelabelcolor4: hsla(8,100%,50%,0.75);
$themelabelcolor4: hsla(190, 100%,50%,0.75);
$themelabelcolor5: hsla(210,100%,50%,0.75);

$themefont: 'Source Sans Pro',sans-serif;
$themefontbold: 'Source Sans Pro',sans-serif;

$themsheet1background:#eee;
$themsheet2background:darken($themsheet1background,5%);
$themsheet3background:darken($themsheet2background,5%);
$themsheet4background:darken($themsheet3background,5%);
$themsheet5background:darken($themsheet4background,5%);
// ---------------------


// don't show lin url in print'
a[href]:after {
    content: "";
}

$a-tags: 'a, a:active, a:hover, a:visited';
$a-tags-hover: 'a:active, a:hover';

#{$a-tags} {
    color: $themecolorlinktext;
    text-decoration: underline;
}
#{$a-tags-hover} {
    color: $themecolorlinktextlight;
    text-decoration: none;
}

// ---------------------
html,body { 
    background: #FAFAFA;
    font-family: $themefont;
    font-weight: 800;
}


.topbar {
    overflow: hidden;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.16), 0 -2px 4px rgba(0, 0, 0, 0.12);
    color: $themecolor;
    background:$themecolor;
    height:0.5em;
    border-top-color: $themecolordarkborder;
    border-top-width: 1px;
    border-top-style: solid;
    border-bottom-color: $themecolorlightborder;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.bottombar {
    @extend .topbar;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.12);
    height:2em;
    border-bottom-color: $themecolorlightborder;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: $themecolordarkborder;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.logo {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
}


.sheet1 {
    background:$themsheet1background;
}

.sheet2 {
    background:$themsheet2background;
}

.sheet3 {
    background:$themsheet3background;
}

.sheet4 {
    background:$themsheet4background;
}

.sheet5 {
    background:$themsheet5background;
}

.bgb0 {
    background-color: $themelabelcolor0;
}
.bgb1 {
    background-color: $themelabelcolor1;
}
.bgb2 {
    background-color: $themelabelcolor2;
}
.bgb3 {
    background-color: $themelabelcolor3;
}
.bgb4 {
    background-color: $themelabelcolor4;
}
.bgb5 {
    background-color: $themelabelcolor5;
}
